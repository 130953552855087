
@font-face {
  font-family: "Helvetica Neue";
  src: url("https: //gitlab.com/orkabit/clients/issamnahhas.com/-/blob/master/src/components/Helvetica-Neue-Font/Helvetica%20Neue%20Medium%20Extended/Helvetica%20Neue%20Medium%20Extended.ttf") format("truetype");
  font-weight: 300;
}

body {
  margin: 0;
  font-family: 'Helvetica', 'Arial', sans-serif !important; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: 0  auto !important;

}

code {
font-family: 'Helvetica', 'Arial', sans-serif !important;   
}
.send-button{
  transition: all 0.5s;
}
.send-button:hover{
  transform: scale(1.02);
}
.img-box{
  background-repeat:no-repeat ;
  background-size: cover;
}
.img-box-mobile{
  background-repeat:no-repeat ;
  background-size: cover;
}
